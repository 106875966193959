import React, {useEffect, useState} from 'react'
import {useNavigate, useLocation} from "react-router-dom";
import axios from 'axios';
import {Row, Card, Popover} from 'antd';
import no_image from './images/no_main.png'

const {Meta} = Card;
const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());

const Categories = ({click, checked, onChange}) => {
  const [categories, setCategoti] = useState([])
  let navigation = useNavigate()
  let location = useLocation()

  useEffect(() => {
    if (location.pathname === '/categories') {
      navigation(location.pathname + location.search)
    } else {
      navigation(location.pathname + 'categories' + location.search)
    }

    axios.get('https://labrehab.productlab.pro/api/category')
      .then(response => {
        if (response.status === 200) {
          setCategoti(response.data)
        }
      })
  }, [])

  const onClickCard = (item) => {
    click(!checked)
    onChange(item.id)
  }

  return (
    <>
      <Row>
        {categories.map((item) => {
          return <Card
            key={item.id}
            style={{
              width: 300,
              marginBottom: 15,
              marginLeft: 5,
              cursor: "pointer"
            }}
            cover={
              <img
                style={{width: 300, height: 300}}
                alt="card_cover"
                src={item.image ? `https://labrehab.productlab.pro/api/${item.image}` : `${no_image}`}
              />
            }
            onClick={() => onClickCard(item)}
          >
            <Popover title={"Нажмите для просмотра статей"}>
              <Meta
                title={item.name}
                description={`Количество статей: ${item.count_articles}`}
              />
            </Popover>
          </Card>
        })}
      </Row>
    </>
  )
}

export default Categories;