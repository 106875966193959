import './App.css'
import React, {useState, useEffect} from 'react';
import {Routes, Route, Link, useNavigate, useLocation} from "react-router-dom";

import ArticleCard from './Card';

import {
  UnorderedListOutlined,
  UserOutlined,
  SettingOutlined,
  FileOutlined,
  CodeOutlined,
  GlobalOutlined
} from '@ant-design/icons';
import {Switch} from 'antd';

import {Layout, Menu} from 'antd';

import axios from 'axios';
import Users from './Users';
import Settings from './Settings';
import FileList from './components/files/List';
import EditArticlePage from './EditArticlePage';
import NewArticlePage from './NewArticlePage';
import Categories from './Categories';
import DismissedPage from "./Dismissed";
import FileCategories from "./components/files/Categories";

const {Header, Content, Footer} = Layout;

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());

const App = () => {
  const [check, setCheck] = useState(false)
  const [userId, setUserId] = useState(false)

  const defaultNavItems = [
    {
      label: <Link to={params.token ? `/?token=${params.token}` : `/`}>Статьи</Link>,
      key: 'articles',
      icon: <UnorderedListOutlined/>
    },
    {
      label: <a href={`https://labrehab.productlab.pro/api/docs`} target="_blank" rel="noopener noreferrer">API Документация</a>,
      key: 'api',
      icon: <CodeOutlined/>
    },
    {
      label: <a href={process.env.REACT_APP_SITE_URL} target="_blank" rel="noopener noreferrer">Сайт</a>,
      key: 'site',
      icon: <GlobalOutlined/>
    }
  ]

  const [state, setState] = useState({
    loaded: false,
    routes: <Routes></Routes>,
    nav_items: defaultNavItems,
    user: false
  })

  let navigation = useNavigate()
  let location = useLocation()
  let isFilePage = location.pathname.includes("/files")

  const setUserPermission = (token, categoryId = 0, fileCategoryId = 0, redirection) => {
    axios.get(`https://labrehab.productlab.pro/api/user/${token}`)
      .then(response => {
          let routes = <Routes></Routes>
          let navItems = defaultNavItems
          setUserId(response.data)

          if (response.data.role === "ADMIN") {
            routes = (
              <Routes>
                <Route
                  path="/"
                  element={<ArticleCard token={params.token} categories={categoryId}/>}
                />
                <Route
                  path='/categories'
                  element={<Categories click={setCheck} onChange={onChange}/>}
                />
                <Route
                  path="/edit"
                  element={<EditArticlePage/>}
                />

                <Route
                  path="/new"
                  element={<NewArticlePage user={userId} token={params.token}/>}
                />

                <Route
                  path="/users"
                  element={<Users token={params.token}/>}
                />

                <Route
                  path="/settings"
                  element={<Settings token={params.token}/>}
                />
                <Route
                  path="/files"
                >
                  <Route index element={<FileList token={params.token} category={fileCategoryId}/>}/>
                  <Route path={"categories"} element={<FileCategories click={setCheck} onChange={onChangeFile}/>}/>
                </Route>
              </Routes>
            )
            navItems = [
              defaultNavItems[0],
              {
                label: <Link to={`/users?token=${params.token}`}>Пользователи</Link>,
                key: 'users',
                icon: <UserOutlined/>
              },
              {
                label: <Link to={`/files?token=${params.token}`}>Файлы</Link>,
                key: 'files',
                icon: <FileOutlined/>
              },
              {
                label: <Link to={`/settings?token=${params.token}`}>Настройки</Link>,
                key: 'settings',
                icon: <SettingOutlined/>
              },
              ...defaultNavItems.slice(1)
            ]
          }

          if (response.data.role === "MANAGER") {
            routes = (
              <Routes>
                <Route
                  path="/"
                  element={<ArticleCard token={params.token} categories={categoryId}/>}
                />
                <Route
                  path='/categories'
                  element={<Categories click={setCheck} onChange={onChange}/>}
                />
                <Route
                  path="/edit"
                  element={<EditArticlePage/>}
                />

                <Route
                  path="/new"
                  element={<NewArticlePage user={userId}/>}
                />
              </Routes>
            )
          }

          if (response.data.role === "COPYWRITER") {
            routes = (
              <Routes>
                <Route
                  path="/"
                  element={<ArticleCard token={params.token} categories={categoryId}/>}
                />
                <Route
                  path="/categories"
                  element={<Categories click={setCheck} onChange={onChange}/>}
                />
                <Route
                  path="/edit"
                  element={<EditArticlePage/>}
                />
              </Routes>
            )
          }

          setState({
            ...state,
            loaded: true,
            user: response.data,
            routes: routes,
            nav_items: navItems
          })
          if (redirection) {
            navigation(redirection + location.search)
          }
        }
      )
      .catch(error => {
        setState({
          ...state,
          loaded: true,
          routes:
            <Routes>
              <Route
                path="/"
                element={<ArticleCard categories={categoryId}/>}
              />
              <Route
                path="/categories"
                element={<Categories click={setCheck} onChange={onChange}/>}
              />
            </Routes>
        })
        if (redirection) {
          navigation(redirection + location.search)
        }
      }
    )
  }

  useEffect(() => {
    if (location.pathname === "/" || location.pathname === "/files") {
      const url = isFilePage ? "/categories" : "categories"
      navigation(location.pathname + url + location.search)
      setCheck(true)
    }

    setUserPermission(params.token)
  }, [])

  const onChange = (id) => {
    setUserPermission(params.token, id, 0, "/")
  }

  const onChangeFile = (id) => {
    setUserPermission(params.token, 0, id,"/files")
  }
  
  const onSwitch = () => {
    setCheck(!check);
    let url = isFilePage ? "/files" : ""
    url = !check ? url + "/" : url + "/categories"
    navigation(url + location.search)
  }

  let selected = []
  selected = ['payments']

  return (
    state.user?.is_dismissed ? (
      <DismissedPage/>
    ) : (
      <Layout>
        <Header
          style={{
            position: 'fixed',
            zIndex: 2,
            width: '100%',
            background: '#fff',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Menu
            theme="light"
            mode="horizontal"
            defaultSelectedKeys={selected}
            items={state.nav_items}
            style={{width: '100%'}}
          />

          <div style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: 'auto',
            gap: '10px',
          }}>
            <span>Категории</span>
            <Switch checked={check} onChange={onSwitch}/>
            <span>{isFilePage ? "Файлы" : "Статьи"}</span>
          </div>
        </Header>

        <Content
          className="site-layout"
          style={{
            padding: '10px 20px',
            marginTop: 64,
          }}
        >
          <div
            className="site-layout-background"
            style={{
              padding: 15,
              minHeight: "83.5vh",
            }}
          >
            {state.routes}
          </div>

        </Content>

        <Footer
          style={{
            textAlign: 'center',
          }}
        >
          2022 Made by @productlab.pro/blog/team
        </Footer>
      </Layout>
    )
  )
}

export default App;
