import {Card, Row, Avatar, Popover, Pagination, Button} from 'antd';
import React from 'react';
import axios from 'axios';
import {EyeOutlined, EditOutlined} from '@ant-design/icons';

import no_image from './images/no_main.png'
import {Link} from "react-router-dom";
import Lottie from 'react-lottie';


const {Meta} = Card;

class ArticleCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      user: false
    }
  }

  ws_connect = () => {

    this.websocket = new WebSocket(`wss://labrehab.productlab.pro/ws/article`)


    this.websocket.onmessage = message => {
      const data = JSON.parse(message.data)

      let new_article_info = null

      if (data.type === "CreateArticle" || data.type === "UpdateArticle") {
        let profile_pic = "https://joeschmoe.io/api/v1/random"
        let owner = "Не указан"
        let owner_role = ""
        let image_src = no_image
        let buttons = [<Button icon={<EyeOutlined/>} type={"link"}
                               href={`https://productlab.pro/article/${data.data.id}`}/>]
        if (data.data.owner && data.data.owner.profile_pic) {
          profile_pic = `https://labrehab.productlab.pro/api/${data.data.owner.profile_pic}`
        }

        if (data.data.main_pic) {
          image_src = `https://labrehab.productlab.pro/api/${data.data.main_pic}`
        }

        if (data.data.owner) {
          owner = data.data.owner.name
          if (data.data.owner.role === "ADMIN") {
            owner_role = "Администратор"
          }
          if (data.data.owner.role === "MANAGER") {
            owner_role = "Менеджер"
          }
          if (data.data.owner.role === "COPYWRITER") {
            owner_role = "Копирайтер"
          }
        }

        if (this.state.user) {
          if (this.state.user.role === "MANAGER") {
            if (data.data.owner.id === this.state.user.id) {
              buttons.unshift(<Link to={`/edit?token=${this.props.token}&id=${data.data.id}`}><EditOutlined/></Link>)
            }
          }
          if (this.state.user.role === "ADMIN") {
            buttons.unshift(<Link to={`/edit?token=${this.props.token}&id=${data.data.id}`}><EditOutlined/></Link>)
          }
          if (this.state.user.role === "COPYWRITER") {
            buttons.unshift(<Link to={`/edit?token=${this.props.token}&id=${data.data.id}`}><EditOutlined/></Link>)
          }
        }

        let is_lottie = false

        if (image_src.split(".").at(-1) === "json") {
          is_lottie = true
        }

        new_article_info = (
          <Card
            key={data.data.id}
            style={{
              width: 300,
              marginBottom: 15,
              marginLeft: 5
            }}
            cover={
              is_lottie ?
                <Lottie
                  width={300}
                  height={300}
                  options={{
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid slice'
                    },
                    loop: true,
                    path: image_src,
                  }}

                />
                :
                <img
                  style={{width: 300, height: 300}}
                  alt="card_cover"
                  src={image_src}
                />
            }
            actions={buttons}
          >
            <Popover content={owner_role} title={owner}>
              <Meta
                avatar={<Avatar src={profile_pic}/>}
                title={data.data.title ? data.data.title : "Нет названия"}
                description={`${data.data.first_sentence ? data.data.first_sentence : "Нет описания"}\n`}
              />
              <br/>
              {data.data.isPublic ? "Статья публичная" : "Статья приватная"}
              <br/>
              {data.data.isPublish ? "Статья обупликована" : "Статья не обупликована"}
            </Popover>
          </Card>
        )
      }

      if (data.type === "CreateArticle") {
        const dataSource = [...this.state.Cards]
        let newDS = []
        if (dataSource.length >= 17) {
          newDS = dataSource.slice(0, 17)
        } else {
          newDS = dataSource
        }

        newDS.unshift(new_article_info)

        let card_c = this.state.CardsC + 1
        this.setState({Cards: newDS, CardsC: card_c})

      }

      if (data.type === "UpdateArticle") {

        const newData = [...this.state.Cards];
        const index = newData.findIndex((item) => data.id.toString() === item.key);

        if (index !== -1) {
          // const item = newData[index];
          newData.splice(index, 1, new_article_info)
          this.setState({Cards: newData});
        }
      }

    }
  }

  componentWillUnmount() {
    this.websocket.close()
    this.websocket = null
  }

  fetch = (page, url = {}, categories) => {
    const limit = 18
    const offset = (page * 18) - 18

    axios.get(url, {params: {limit: limit, offset: offset, show_unpublic: true, categories_ids: categories}})
      .then(response => {
        let cards = []
        for (var i = 0; i < response.data.result.length; i++) {
          let profile_pic = "https://joeschmoe.io/api/v1/random"
          let owner = "Не указан"
          let owner_role = ""
          let image_src = no_image
          let buttons = [<Button icon={<EyeOutlined/>} type={"link"}
                                 href={`https://productlab.pro/article/${response.data.result[i].id}`}/>]
          if (response.data.result[i].owner && response.data.result[i].owner.profile_pic) {
            profile_pic = `https://labrehab.productlab.pro/api/${response.data.result[i].owner.profile_pic}`
          }

          if (response.data.result[i].main_pic) {
            image_src = `https://labrehab.productlab.pro/api/${response.data.result[i].main_pic}`
          }

          if (response.data.result[i].owner) {
            owner = response.data.result[i].owner.name
            if (response.data.result[i].owner.role === "ADMIN") {
              owner_role = "Администратор"
            }
            if (response.data.result[i].owner.role === "MANAGER") {
              owner_role = "Менеджер"
            }
            if (response.data.result[i].owner.role === "COPYWRITER") {
              owner_role = "Копирайтер"
            }
          }

          if (this.state.user) {
            if (this.state.user.role === "MANAGER") {
              if (response.data.result[i].owner.id === this.state.user.id) {
                // buttons.unshift(<EditArticle user={this.state.user} article={response.data.result[i]} token={this.props.token} />)
                buttons.unshift(<Link
                  to={`/edit?token=${this.props.token}&id=${response.data.result[i].id}`}><EditOutlined/></Link>)
              }
            }
            if (this.state.user.role === "ADMIN") {
              // buttons.unshift(<EditArticle user={this.state.user} article={response.data.result[i]} token={this.props.token} />)
              buttons.unshift(<Link
                to={`/edit?token=${this.props.token}&id=${response.data.result[i].id}`}><EditOutlined/></Link>)
            }
            if (this.state.user.role === "COPYWRITER") {
              buttons.unshift(<Link
                to={`/edit?token=${this.props.token}&id=${response.data.result[i].id}`}><EditOutlined/></Link>)
            }
          }

          let is_lottie = false

          if (image_src.split(".").at(-1) === "json") {
            is_lottie = true
          }

          cards.push(
            <Card
              key={response.data.result[i].id}
              style={{
                width: 300,
                marginBottom: 15,
                marginLeft: 5
              }}
              cover={
                is_lottie ?
                  <Lottie
                    width={300}
                    height={300}
                    options={{
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice'
                      },
                      loop: true,
                      path: image_src,
                    }}

                  />
                  :
                  <img
                    style={{width: 300, height: 300}}
                    alt="card_cover"
                    src={image_src}
                  />
              }
              actions={buttons}
            >
              <Popover content={owner_role} title={owner}>
                <Meta
                  style={{height: 130}}
                  avatar={<Avatar src={profile_pic}/>}
                  title={response.data.result[i].title ? response.data.result[i].title : "Нет названия"}
                  description={`${response.data.result[i].first_sentence ? response.data.result[i].first_sentence : "Нет описания"}\n`}
                  // description={description}
                />
                <br/>
                {response.data.result[i].isPublic ? "Статья публичная" : "Статья приватная"}
                <br/>
                {response.data.result[i].isPublish ? "Статья обупликована" : "Статья не обупликована"}
              </Popover>
            </Card>
            // </Col>
          )
        }
        this.setState({Cards: cards, CardsC: response.data.count, loaded: true})
      });
  }

  fetchUser = () => {
    if (this.props.token) {
      axios.get(`https://labrehab.productlab.pro/api/user/${this.props.token}`)
        .then(response => {
          if (response.status === 200) {
            this.setState({user: response.data})
          }
        })
    }
  }

  componentDidMount() {
    this.fetch(1, `https://labrehab.productlab.pro/api/article`, this.props.categories);
    this.ws_connect();
    this.fetchUser()
  }

  render() {
    return (
      this.state.loaded ? <div>

        {/* <Link to={`/edit?token=${this.props.token}&id=${response.data.result[i].id}`}><EditOutlined /></Link> */}
        {/* {this.state.user ? <NewArticle user={this.state.user} token={this.props.token} /> : null} */}

        {this.state.user ?
          <Link to={`/new?token=${this.props.token}`}><Button style={{marginBottom: 15, marginTop: -20}} type="default">Новая
            статья</Button></Link> : null}
        <br/>

        <Row>
          {this.state.Cards}
        </Row>
        <Pagination
          onChange={(page) => {
            this.fetch(page, 'https://labrehab.productlab.pro/api/article')
          }}
          pageSize={18}
          total={this.state.CardsC}
          style={{float: "right"}}
          showTotal={(total, range) => `Показаны ${range[0]}-${range[1]} из ${total} статей`}
        />
      </div> : null
    )
  }
}

export default ArticleCard;